<template>
  <div class="base-info-wrapper content-wrapper">
    <div class="card-box">
      <van-cell
        class="border-bottom-1px avatar-card"
        title="头像"
        :border="false">
        <img class="avatar" :src="userInfo.headUrl" alt="">
      </van-cell>
      <van-cell
        class="border-bottom-1px"
        title="名字"
        :value="userInfo.userName"
        :border="false" />
      <!-- <van-cell
        class="border-bottom-1px"
        title="微信号"
        :value="userInfo.userName"
        :border="false" /> -->
      <van-cell
        title="手机号码"
        to="/baseInfo/changePhone"
        is-link
        :value="userInfo.phone"
        :border="false" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'baseInfo',
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
  .base-info-wrapper{
    display: flex;
    flex-direction: column;
    .info-content{
      flex: 1;
      padding: 24px;
      box-sizing: border-box;
    }
    .card-box{
      margin-bottom: 24px;
      padding: 20px 24px;
      &.avatar-card{
        padding: 14px 24px;
      }
      .avatar{
        width: 61px;
        height: 61px;
        border-radius: 50%;
      }
    }
  }
</style>
<style lang="scss">
  .base-info-wrapper .avatar-card{
    align-items: center;
    .van-cell__title, .van-cell__value, .van-icon{
      display: flex;
      align-items: center;
    }
    .van-cell__value{
      justify-content: flex-end;
    }
  }
</style>
